import './App.css';
import {useEffect, useState} from "react";
import { CSSTransition } from "react-transition-group";

function App() {
  const [showTitle, setShowTitle] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showInfoDelayed, setShowInfoDelayed] = useState(false);
  
  useEffect(() => {
    setTimeout(() => {
      setShowTitle(true);
    }, 500);
    setTimeout(() => {
      setShowInfo(true);
    }, 2000);
    setTimeout(() => {
      setShowInfoDelayed(true);
    }, 2500);
  })
  
  return (
    <div className="App">
      <div className="App-header">
        <div className="body-container" style={{paddingBottom: '10%'}}>
          <div className={`title ${showTitle ? 'appeared' : ''} ${showInfo ? 'moved-up' : ''}`}>
            <CSSTransition in={showTitle} classNames='title-box' timeout={300} unmountOnExit>
              <div>
                <h1>Van Krasnov, M.D.</h1>
                <h2>Child and Adolescent Psychiatrist</h2>
                <h2>Santa Monica, California</h2>
              </div>
            </CSSTransition>
          </div>
          <div className={`info ${showInfo ? 'appeared' : ''}`}>
            <CSSTransition in={showInfoDelayed} classNames='info-box' timeout={300} unmountOnExit>
            <div>
            Parenting a struggling child can feel overwhelming. Is their anxiety driving success, 
            or has it become a burden? Is trouble focusing just a phase, or a sign of 
            something deeper? My role isn’t to change what makes your child unique but to help 
            them harness their strengths and heal from what’s holding them back. <br/><br/>
            
            As a child and adolescent psychiatrist, I blend psychotherapy with the thoughtful 
            use of medication when needed. I take a holistic approach, considering medical, 
            psychological, and social factors. Your trust means everything to me. I’ll work 
            closely with you and your child, providing steady support as we navigate challenges 
            together and find a way forward that honors who they are.
            </div>

            </CSSTransition>
          </div>
          <CSSTransition in={showInfoDelayed} classNames='info-box' timeout={300} unmountOnExit>
            <div className="dock">
              <div className="dock-item">
                <a href="tel:+13103406616">(310) 340-6616</a>
              </div>
              <div className="dock-item">
                <a href="mailto:VanKrasnovMD@mdofficemail.com">VanKrasnovMD@mdofficemail.com</a>
              </div>
            </div>
          </CSSTransition>
        </div>
        <div className="body-container">
          <CSSTransition in={showInfoDelayed} classNames='info-box' timeout={300} unmountOnExit>
            <div className="youtube" style={{display: 'block', margin: '0 auto'}}>
              <iframe src="https://www.youtube.com/embed/yv9-I2r3IiI?si=34_KiL0KCt0bY4Y6"
                      title="YouTube video player" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
          </CSSTransition>
        </div>
      </div>
      <footer style={{ textAlign: "center", padding: "1em", backgroundColor: "#f1f1f1" }}>
        <p>
          Providing psychiatric care for children and adolescents in Santa Monica, specializing in anxiety, ADHD, and behavioral issues.
        </p>
      </footer>
    </div>
  );
}

export default App;
